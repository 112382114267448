import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Link from "gatsby-link";

const ShopNav = () => {
  return (
    <StaticQuery
      query={graphql`
        query navQuery {
          allSanityCollection(
            filter: { slug: { current: { ne: null } } }
            sort: { fields: number, order: DESC }
          ) {
            nodes {
              title
              slug {
                current
              }
            }
          }
        }
      `}
      render={data => {
        var collections = data.allSanityCollection.nodes;
        return (
          <>
            {collections.map(c => (
              <Link
                key={c.slug.current}
                className="navbar-item"
                to={`/shop/${c.slug.current}`}
              >
                {c.title}
              </Link>
            ))}
            <Link className="navbar-item" to="/shop">
              {" "}
              Shop all
            </Link>
          </>
        );
      }}
    />
  );
};

export default ShopNav;
