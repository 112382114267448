import React from "react";

import Nav from "./nav";
import Footer from "./footer";
import { useSiteSettings } from "../hooks/useSiteSettings";
import TopBar from "./topBar";

import "../styles/layout.scss";

const Layout = ({ children, mainClass = "" }) => {
  const { topBarText, topBarColour } = useSiteSettings();
  const topBarEnabled = topBarText !== null && topBarText !== "";

  return (
    <>
      {topBarEnabled && (
        <TopBar colorHex={topBarColour.hex} text={topBarText} />
      )}
      <section
        className={`layout container ${topBarEnabled ? "with-topbar" : ""}`}
      >
        <Nav />
        <main className={`main ${mainClass}`}>{children}</main>
        <Footer />
      </section>
    </>
  );
};

export default Layout;
