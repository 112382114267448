import React, { useState } from "react";
import { Link } from "gatsby";
import { useSiteSettings } from "../hooks/useSiteSettings";

import { Logo } from "./logo";

import "../styles/nav.scss";
import ShopNav from "./shopNav";

const Nav = ({ container = true }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleBurgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  const Container = ({ container, children }) =>
    container ? <div className="container">{children}</div> : <>{children}</>;

  const { enableStore } = useSiteSettings();

  return (
    <nav className="navbar" role="navigation">
      <Container container={container}>
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <Logo />
          </Link>

          <button
            className={`navbar-burger ${menuOpen ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={handleBurgerClick}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
          <div className="navbar-end">
            {enableStore && (
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">shop</div>
                <div className="navbar-dropdown">
                  <ShopNav />
                </div>
              </div>
            )}
            <Link className="navbar-item" to="/events">
              events
            </Link>
            <Link className="navbar-item" to="/about">
              about
            </Link>
            <Link className="navbar-item" to="/suppliers">
              suppliers
            </Link>
            <Link className="navbar-item" to="/contact">
              contact
            </Link>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Nav;
