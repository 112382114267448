import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "../styles/newsletter-form.scss";

const MyForm = ({ subscribe, status }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [saleCity, setSaleCity] = useState("");
  const [saleCityError, setSaleCityError] = useState(false);

  const onEmailChange = e => {
    var value = e.target.value;
    setEmail(value);
    setEmailError(false);
  };

  const onCityChange = e => {
    var value = e.target.value;
    setSaleCity(value);
    setSaleCityError(false);
  };

  const submit = () => {
    if (email == "" && email.indexOf("@") == -1) {
      setEmailError(true);
    }
    if (saleCity == "") {
      setSaleCityError(true);
    }

    if (!emailError && !saleCityError) {
      subscribe({
        EMAIL: email,
        SALE_CITY: saleCity,
      });
    }
  };

  return (
    <div className="form">
      {status === "success" ? (
        <div className="success">Welcome to the club!</div>
      ) : (
        <>
          <div className="field has-addons">
            <div className="control control-field">
              <input
                className={`input ${emailError && "is-danger"}`}
                id="email"
                type="text"
                placeholder="Email address"
                value={email}
                onChange={onEmailChange}
              />
            </div>
          </div>

          <div className="field has-addons">
            <div className={`select ${saleCityError && "is-danger"}`}>
              <select onBlur={onCityChange} defaultValue="">
                <option disabled value="">
                  Notify me about sales in
                </option>
                <option value="Melbourne">Melbourne</option>
                <option value="Sydney">Sydney</option>
                <option value="Melbourne & Sydney">Melbourne & Sydney</option>
              </select>
            </div>
            <div className="control">
              <button
                className={`button ${status === "sending" && "is-loading"}`}
                onClick={submit}
              >
                Subscribe
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const MailchimpForm = () => {
  const mcUrl =
    "//markdownmarche.us17.list-manage.com/subscribe/post?u=b1520bac178ffdcbef0b59363&amp;id=b7fec95044";

  return (
    <MailchimpSubscribe
      url={mcUrl}
      render={({ subscribe, status, message }) => (
        <MyForm subscribe={subscribe} status={status} />
      )}
    />
  );
};

export default MailchimpForm;
