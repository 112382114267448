import React from "react";

import LogoSrc from "../images/logo.png";
import LogoMmSrc from "../images/logo-mm.png";

export const Logo = ({ className = "" }) => (
  <img src={LogoSrc} alt="Markdown Marche" className={`logo ${className}`} />
);

export const LogoMm = ({ className = "" }) => (
  <img
    src={LogoMmSrc}
    alt="Markdown Marche"
    className={`logo-mm ${className}`}
  />
);

export const LogoNav = () => <div alt="Markdown Marche" className="logo-nav" />;
