import React from "react";

const TopBar = ({ text, colorHex }) => {
  return (
    <div className="topbar-background" style={{ backgroundColor: colorHex }}>
      <div className="container">
        <div className="topbar-text">{text}</div>
      </div>
    </div>
  );
};

export default TopBar;
