import { useStaticQuery, graphql } from "gatsby";

export const useSiteSettings = () => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings(_id: { eq: "single-siteSettings" }) {
        enableStore
        topBarText
        topBarColour {
          hex
        }
      }
    }
  `);

  return query.sanitySiteSettings;
};
