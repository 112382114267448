import React from "react";
import { Link } from "gatsby";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

import MailchimpForm from "./mailchimpForm";

import "../styles/footer.scss";

const Footer = () => {
  return (
    <footer className="footer-bar">
      <div className="container">
        <div className="newsletter">
          <div className="label-container">
            <label className="label" htmlFor="email">
              Newsletter
            </label>
          </div>
          <MailchimpForm />
        </div>
        <div className="links">
          <div className="social">
            <a href="https://www.instagram.com/markdownmarche/">
              <span className="icon">
                <FaInstagram size="1.5em" />
              </span>
              <span className="is-sr-only">@markdownmarche</span>
            </a>
            <a href="https://www.facebook.com/markdownmarche/">
              <span className="icon">
                <FaFacebook size="1.5em" />
              </span>
              <span className="is-sr-only">markdownmarche</span>
            </a>
          </div>
          <div className="policies">
            <Link to="/sizing">Sizing</Link>
            <Link to="/shipping-returns">Shipping & Returns</Link>
            <Link to="/terms">Terms</Link>
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
